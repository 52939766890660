import { Component } from '@angular/core';
import { ActivationStart, Router, RouterOutlet } from '@angular/router';
import { InituserService } from './inituser/inituser.service';
import { User } from './shared/services/user';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public loggedInUser: User;
  public appPages = [
    // { title: 'Inbox', url: '/folder/Inbox', icon: 'mail' },
    // { title: 'Outbox', url: '/folder/Outbox', icon: 'paper-plane' },
    // { title: 'Favorites', url: '/folder/Favorites', icon: 'heart' },
    // { title: 'Archived', url: '/folder/Archived', icon: 'archive' },
    // { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
    // { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  constructor(private router:Router,   private userProvider: InituserService,) {

    // this.loggedInUser = this.userProvider.getUserData();
    // if (this.loggedInUser.id) {
    //   this.router.navigate(['/dashboard']);
    // } else {
    //      this.router.navigate(['/login']);
    // }
  }
}
