import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CommonserviceService {

  constructor(private toastr:ToastrService) { }

  showSuccess(message) {
    this.toastr.success(message);
  }

  showFail(message){
    this.toastr.error(message)
  }
}
