import { Injectable, NgZone } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, from } from 'rxjs';
import { FirestoreService } from './firestore.service';
import { AuthenticationService } from './firebase-authentication.service';
import { Router } from '@angular/router';
import { CommonserviceService } from '../commonservice.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { User } from '../shared/services/user';

const BaseUrl = environment.url;
@Injectable({
  providedIn: 'root'
})
export class ServicesService {
  userData: any; // Save logged in user data
  id: any;

  constructor(private http:HttpClient,
    private firestore: FirestoreService,
    private auth: AuthenticationService,
    private router:Router,
    private communicationService:CommonserviceService,
      public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service 
    public ngZone: NgZone 
    
    ) { 
     this.id= localStorage.getItem('id');
     console.log(this.id);
     
      this.afAuth.authState.subscribe(user => {
        if (user) {
          console.log(user,"user");
          
        //   this.userData = user;
        //   localStorage.setItem('user', JSON.stringify(this.userData));
        //   JSON.parse(localStorage.getItem('user'));
        // } else {
        //   localStorage.setItem('user', null);
        //   JSON.parse(localStorage.getItem('user'));
        }
      })
    }

    updateToken(id) {
      // this.id = id;
    }

  signUp(user): Observable<any> {
    const userInfo = {
      'id': null,
      'email': user['email'],
      'displayName': `${user['name']}`,
      'password':user['password']
    };

    return new Observable((observer) => {
      this.auth.createAccount(user['email'], user['password'])
        .then(user => {
          console.log(user);
          // this.SetUserData(user.user);
          userInfo.id = user.uid;
          this.firestore.createWithId('usersdata', userInfo).then(usr => {
            console.log(usr);
            observer.next(userInfo);
          }, err => {
            observer.error(err);
          });
          userInfo.id = user.uid;
        }).catch(err => {
          observer.error(err);
        });
    });

  }

  logIn(username: string, password: string): Observable<any> {
    return new Observable((observer) => {
      this.auth.login(username, password)
        .then(user => {
          console.log(user);
          this.id= user.uid;
          localStorage.setItem('id',this.id);
          // this.SetUserData(user.user);
          observer.next({ id: user.uid });
        }).catch(err => {
          observer.error(err);
        });
    });

  }

  forgotPassword(email):Observable<any>{

    return new Observable((observer) => {
      this.afAuth.sendPasswordResetEmail(email)
        .then(res => {
        console.log(res);
          observer.next(res);
        }).catch(err => {
          observer.error(err);
        });
    });

  }

  logout() {
    return this.auth.logout();
  }

  updateUser(id, userData): Observable<any> {
    console.log('updateuser');
    return from(this.firestore.update('usersdata', id, userData));
  }

  // SetUserData(user) {
  //   const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
  //   const userData: User = {
  //     uid: user.uid,
  //     email: user.email,
  //     displayName: user.displayName,
  //     photoURL: user.photoURL,
  //     emailVerified: user.emailVerified
  //   }
  //   return userRef.set(userData, {
  //     merge: true
  //   })
  // }

  getUser(): Observable<any> {
    console.log('id', this.id);
    return this.addIdToObject(this.id, this.firestore.getOne('usersdata', this.id));
  }

  addIdToObject(id, obj: Observable<any>) {
    return new Observable((observer) => {
      if (id) {
        obj.subscribe(ref => {
          const newObj = ref;
          if(newObj){
            newObj.id = id;
          }
          observer.next(newObj);
        }, err => {
          observer.error(err);
        });
      } else {
        observer.error({ message: 'No ID' });
      }
    });
  }


}
