import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  setUser(user: any) {
    localStorage.setItem('user', JSON.stringify(user));
    }

  getUser() {
    return JSON.parse(localStorage.getItem('user'));
  }
}
