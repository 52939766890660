import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [

  {
    path: 'index',
    loadChildren: () => import('./page/index/index.module').then( m => m.IndexPageModule)
  },
  {
    path: '',
    redirectTo:'index',
    pathMatch:'full'
  },
  {
    path: 'modal',
    loadChildren: () => import('./page/modal/modal.module').then( m => m.ModalPageModule)
  },
  {
    path: 'edit-a-goal',
    loadChildren: () => import('./page/edit-a-goal/edit-a-goal.module').then( m => m.EditAGoalPageModule)
  },
  {
    path: 'edit-editor',
    loadChildren: () => import('./page/edit-editor/edit-editor.module').then( m => m.EditEditorPageModule)
  },
  // {
  //   path: 'header',
  //   loadChildren: () => import('./page/header/header.module').then( m => m.HeaderPageModule)
  // },
  // {
  //   path: 'footer',
  //   loadChildren: () => import('./page/footer/footer.module').then( m => m.FooterPageModule)
  // },
  {
    path: 'home',
    loadChildren: () => import('./page/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./page/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./page/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./page/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'forgotpassword',
    loadChildren: () => import('./page/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'personalinfo',
    loadChildren: () => import('./page/personalinfo/personalinfo.module').then( m => m.PersonalinfoPageModule)
  },
  {
    path: 'content-calender',
    loadChildren: () => import('./page/content-calender/content-calender.module').then( m => m.ContentCalenderPageModule)
  },
  {
    path: 'brand-builder',
    loadChildren: () => import('./page/brand-builder/brand-builder.module').then( m => m.BrandBuilderPageModule)
  },
  {
    path: 'community-center',
    loadChildren: () => import('./page/community-center/community-center.module').then( m => m.CommunityCenterPageModule)
  },
  {
    path: 'create-yt-video',
    loadChildren: () => import('./page/create-yt-video/create-yt-video.module').then( m => m.CreateYtVideoPageModule)
  },
  {
    path: 'channel-profile',
    loadChildren: () => import('./page/channel-profile/channel-profile.module').then( m => m.ChannelProfilePageModule)
    },
    {
      path: 'create-tweet',
      loadChildren: () => import('./page/create-tweet/create-tweet.module').then( m => m.CreateTweetPageModule)
    },
  {
    path: 'draft',
    loadChildren: () => import('./page/draft/draft.module').then( m => m.DraftPageModule)
  },
  {
    path: 'create-youtube-video',
    loadChildren: () => import('./page/create-youtube-video/create-youtube-video.module').then( m => m.CreateYoutubeVideoPageModule)
  },
  {
    path: 'create-promotion-post',
    loadChildren: () => import('./page/create-promotion-post/create-promotion-post.module').then( m => m.CreatePromotionPostPageModule)
  },
  {
    path: 'create-a-tweet',
    loadChildren: () => import('./page/create-a-tweet/create-a-tweet.module').then( m => m.CreateATweetPageModule)
  },
  {
    path: 'cross-promotion',
    loadChildren: () => import('./page/cross-promotion/cross-promotion.module').then( m => m.CrossPromotionPageModule)
  },
  {
    path: 'content-calender-week',
    loadChildren: () => import('./page/content-calender-week/content-calender-week.module').then( m => m.ContentCalenderWeekPageModule)
  },
  {
    path: 'goals-dropdown',
    loadChildren: () => import('./page/goals-dropdown/goals-dropdown.module').then( m => m.GoalsDropdownPageModule)
  },
  {
    path: 'update-password',
    loadChildren: () => import('./page/update-password/update-password.module').then( m => m.UpdatePasswordPageModule)
  },
  {
    path: 'cross-promo-uploadvideo',
    loadChildren: () => import('./page/cross-promo-uploadvideo/cross-promo-uploadvideo.module').then( m => m.CrossPromoUploadvideoPageModule)
  },
  {
    path: 'createytvideo-ideag',
    loadChildren: () => import('./page/createytvideo-ideag/createytvideo-ideag.module').then( m => m.CreateytvideoIdeagPageModule)
  },
  
  {
    path: 'createtweet-ideag',
    loadChildren: () => import('./page/createtweet-ideag/createtweet-ideag.module').then( m => m.CreatetweetIdeagPageModule)
  },
  {
    path: 'ytvideo-suggest',
    loadChildren: () => import('./page/ytvideo-suggest/ytvideo-suggest.module').then( m => m.YtvideoSuggestPageModule)
  },
  {
    path: 'crosspromo-thumbnail',
    loadChildren: () => import('./page/crosspromo-thumbnail/crosspromo-thumbnail.module').then( m => m.CrosspromoThumbnailPageModule)
  },
  {
    path: 'choosevideo-promo',
    loadChildren: () => import('./page/choosevideo-promo/choosevideo-promo.module').then( m => m.ChoosevideoPromoPageModule)
  },
  {
    path: 'signup-addaccounts',
    loadChildren: () => import('./page/signup-addaccounts/signup-addaccounts.module').then( m => m.SignupAddaccountsPageModule)
  },
  {
    path: 'createatweet-schedule',
    loadChildren: () => import('./page/createatweet-schedule/createatweet-schedule.module').then( m => m.CreateatweetSchedulePageModule)
  },
  {
    path: 'createytvideo-schedule',
    loadChildren: () => import('./page/createytvideo-schedule/createytvideo-schedule.module').then( m => m.CreateytvideoSchedulePageModule)
  },
  {
    path: 'brandbuilder-twitter',
    loadChildren: () => import('./page/brandbuilder-twitter/brandbuilder-twitter.module').then( m => m.BrandbuilderTwitterPageModule)
  },
  {
    path: 'crosspromotion',
    loadChildren: () => import('./page/crosspromotion/crosspromotion.module').then( m => m.CrosspromotionPageModule)
  },
  {
    path: 'brandbuilder-yt-playlist',
    loadChildren: () => import('./page/brandbuilder-yt-playlist/brandbuilder-yt-playlist.module').then( m => m.BrandbuilderYtPlaylistPageModule)
  },
  {
    path: 'completeddailytasks',
    loadChildren: () => import('./page/completeddailytasks/completeddailytasks.module').then( m => m.CompleteddailytasksPageModule)
  },
  {
    path: 'createatweet',
    loadChildren: () => import('./page/createatweet/createatweet.module').then( m => m.CreateatweetPageModule)
  },
  {
    path: 'createcrosspromotional-tweets',
    loadChildren: () => import('./page/createcrosspromotional-tweets/createcrosspromotional-tweets.module').then( m => m.CreatecrosspromotionalTweetsPageModule)
  },
  {
    path: 'create-ytvideo-addtoplaylist',
    loadChildren: () => import('./page/create-ytvideo-addtoplaylist/create-ytvideo-addtoplaylist.module').then( m => m.CreateYTvideoAddtoplaylistPageModule)
  },
  {
    path: 'confirm',
    loadChildren: () => import('./page/confirm/confirm.module').then( m => m.ConfirmPageModule)
  },
  {
    path: 'create-ytvideo-caption',
    loadChildren: () => import('./page/create-ytvideo-caption/create-ytvideo-caption.module').then( m => m.CreateYTvideoCaptionPageModule)
  },
  {
    path: 'create-ytvideo',
    loadChildren: () => import('./page/create-ytvideo/create-ytvideo.module').then( m => m.CreateYTvideoPageModule)
  },
  {
    path: 'create-ytvideo-uploaded',
    loadChildren: () => import('./page/create-ytvideo-uploaded/create-ytvideo-uploaded.module').then( m => m.CreateYTvideoUploadedPageModule)
  },
  {
    path: 'createbands-filled',
    loadChildren: () => import('./page/createbands-filled/createbands-filled.module').then( m => m.CreatebandsFilledPageModule)
  },
  {
    path: 'crosspromotion-confirm',
    loadChildren: () => import('./page/crosspromotion-confirm/crosspromotion-confirm.module').then( m => m.CrosspromotionConfirmPageModule)
  },
  {
    path: 'signup-goals',
    loadChildren: () => import('./page/signup-goals/signup-goals.module').then( m => m.SignupGoalsPageModule)
  },
  {
    path: 'createyt-uploading',
    loadChildren: () => import('./page/createyt-uploading/createyt-uploading.module').then( m => m.CreateytUploadingPageModule)
  },
  {
    path: 'createytvideo-uplod',
    loadChildren: () => import('./page/createytvideo-uplod/createytvideo-uplod.module').then( m => m.CreateytvideoUplodPageModule)
  },
  {
    path: 'contentc-create',
    loadChildren: () => import('./page/contentc-create/contentc-create.module').then( m => m.ContentcCreatePageModule)
  },
  {
    path: 'create-ytvideo-confirm',
    loadChildren: () => import('./page/create-ytvideo-confirm/create-ytvideo-confirm.module').then( m => m.CreateYTvideoConfirmPageModule)
  },
  {
    path: 'create-ytvideo-metadata',
    loadChildren: () => import('./page/create-ytvideo-metadata/create-ytvideo-metadata.module').then( m => m.CreateYTvideoMetadataPageModule)
  },
  {
    path: 'create-ytvideo-thumbnail',
    loadChildren: () => import('./page/create-ytvideo-thumbnail/create-ytvideo-thumbnail.module').then( m => m.CreateYTvideoThumbnailPageModule)
  },
  {
    path: 'signup-goalsadd',
    loadChildren: () => import('./page/signup-goalsadd/signup-goalsadd.module').then( m => m.SignupGoalsaddPageModule)
  },
  {
    path: 'createatweet-write',
    loadChildren: () => import('./page/createatweet-write/createatweet-write.module').then( m => m.CreateatweetWritePageModule)
  },
  {
    path: 'editplaylist-settings',
    loadChildren: () => import('./page/editplaylist-settings/editplaylist-settings.module').then( m => m.EditplaylistSettingsPageModule)
  },
  {
    path: 'addgoals-addnew',
    loadChildren: () => import('./page/addgoals-addnew/addgoals-addnew.module').then( m => m.AddgoalsAddnewPageModule)
  },
  {
    path: 'signup-createbrands',
    loadChildren: () => import('./page/signup-createbrands/signup-createbrands.module').then( m => m.SignupCreatebrandsPageModule)
  },
  {
    path: 'card-added',
    loadChildren: () => import('./page/card-added/card-added.module').then( m => m.CardAddedPageModule)
  },
  {
    path: 'signup-createmultiplebrands',
    loadChildren: () => import('./page/signup-createmultiplebrands/signup-createmultiplebrands.module').then( m => m.SignupCreatemultiplebrandsPageModule)
  },
  {
    path: 'brandbuilder-yt',
    loadChildren: () => import('./page/brandbuilder-yt/brandbuilder-yt.module').then( m => m.BrandbuilderYtPageModule)
  },
  {
    path: 'signupaddaccount',
    loadChildren: () => import('./page/signupaddaccount/signupaddaccount.module').then( m => m.SignupaddaccountPageModule)
  },
  {
    path: 'reply-tocomments',
    loadChildren: () => import('./page/reply-tocomments/reply-tocomments.module').then( m => m.ReplyTocommentsPageModule)
  },
  {
    path: 'crosspromotion-write',
    loadChildren: () => import('./page/crosspromotion-write/crosspromotion-write.module').then( m => m.CrosspromotionWritePageModule)
  },
  {
    path: 'crosspromotion-metadata',
    loadChildren: () => import('./page/crosspromotion-metadata/crosspromotion-metadata.module').then( m => m.CrosspromotionMetadataPageModule)
  },
  {
    path: 'myaccount-goals',
    loadChildren: () => import('./page/myaccount-goals/myaccount-goals.module').then( m => m.MyaccountGoalsPageModule)
  },

  {
    path: 'brandbuilder-yt-about',
    loadChildren: () => import('./page/brandbuilder-yt-about/brandbuilder-yt-about.module').then( m => m.BrandbuilderYtAboutPageModule)
  },
  {
    path: 'optimize-ytpage',
    loadChildren: () => import('./page/optimize-ytpage/optimize-ytpage.module').then( m => m.OptimizeYtpagePageModule)
  },
  {
    path: 'brandbuilder-yt-upload',
    loadChildren: () => import('./page/brandbuilder-yt-upload/brandbuilder-yt-upload.module').then( m => m.BrandbuilderYtUploadPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
