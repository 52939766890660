import { Injectable } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';
import { ServicesService } from '../api/services.service';
import { User } from '../shared/services/user';
import { StorageService } from '../storage.service';

@Injectable({
  providedIn: 'root'
})
export class InituserService {

  public loggedInUser: User;

  constructor(
    private storage: StorageService,
    private api: ServicesService,
    private loadingCtrl: LoadingController,
    // public util: UtilService,
    private toastCtrl: ToastController
  ) {
    this.createNewEmptyUser();
  }

  getUserData(): User {
    return this.loggedInUser;
  }

    createNewEmptyUser() {
      this.loggedInUser = {
        id: null,
        displayName: '',
        email: '',
        phone: '',
        password: '',
        location_lat: 0,
        location_lng: 0,
        token: '',
        rideId: 0,
        location: ''
      };
    }

  load() {
    return new Promise((resolve, reject) => {
      this.getToken().then(token => {
        this.api.updateToken(token);
        this.api.getUser().subscribe((user: any) => {
          if (user) {
            this.setLoggedInUser(user);
          }
          resolve(true);
        }, err => {
          resolve(true);
          console.log(err);
        });
      });
    });
  }


  async setToken(token) {
    this.api.updateToken(token);
    await localStorage.setItem('token', token);
  }

  async getToken() {
    const token = await localStorage.getItem('token');
    return token;
  }

  async setLoggedInUser(user) {
    Object.assign(this.loggedInUser, user);
    this.loggedInUser.token = await this.getToken();
    await localStorage.setItem('id', user.id);
    console.log('SetLoggedinUser', this.loggedInUser);
  }

  async logout(): Promise<any> {
    this.createNewEmptyUser();
    await this.api.logout();
    return localStorage.clear();
  }

  getLocalUrl(_imagePath): Promise<{ url: string; nativeUrl: string; }> {
    return new Promise((resolve, reject) => {
      const name = _imagePath.split('/');
      this.makeFileIntoBlob(_imagePath, name[name.length - 1]).then((image) => {
        resolve({ url: window.URL.createObjectURL(image), nativeUrl: _imagePath });
      }).catch(error => {
        reject(error);

      });
    });
  }

  makeFileIntoBlob(_imagePath, fileName) {
    return new Promise((resolve, reject) => {
      window['resolveLocalFileSystemURL'](_imagePath, (fileEntry) => {
        fileEntry['file']((resFile) => {
          const reader = new FileReader();
          reader.onload = (evt: any) => {
            const imgBlob: any = new Blob([evt.target.result], { type: 'image/jpeg' });
            imgBlob.name = fileName;
            resolve(imgBlob);
          };
          reader.onloadend = (evt: any) => {
            const imgBlob: any = new Blob([evt.target.result], { type: 'image/jpeg' });
            imgBlob.name = fileName;
            resolve(imgBlob);
          };

          reader.onerror = (e) => {

            reject(e);
          };

          reader.readAsArrayBuffer(resFile);
        }, (err) => {

          reject(err);
        });
      }, (err) => {
      });
    });
  }

  async createLoader(message): Promise<HTMLIonLoadingElement> {
    const loader = await this.loadingCtrl.create({
      message
      // duration: 3000
    });
    return loader;
  }

  async createToast(message, showCloseButton = false, position = 'bottom' as 'top' | 'bottom' | 'middle', duration = 2000): Promise<HTMLIonToastElement> {
    const toast = await this.toastCtrl.create({
      message,
      position,
      duration,
      buttons: [{
        text: 'Done',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }]
    });
    return toast;
  }
}
